<script setup>
import HeaderMenu from '@/components/common/header-menu.vue'
import HomePage from './home-page.vue'
import HomeCore from './home-core.vue'
import Wander from './home-wander.vue'
import HomeISC from './home-isc.vue'
import HomePartner from './home-partner.vue'
import HomeAbout from './home-about.vue'
import _ from 'lodash' // 防抖函数
import { ref, onMounted } from 'vue'
import { coreArr } from '@/mock/index'
const popoverStyle = {
  textAlign: 'center',
  boxShadow: '0 6px 16px 0 #00000033',
  borderRadius: '12px',
  padding: '0',
  overflow: 'hidden'
}
const carousel = ref(null)
const tagArr = [
  {
    id: 0,
    key: '',
    title: 'ISC使命'
  },
  {
    id: 1,
    key: 'marketing',
    title: '会展营销'
  },
  {
    id: 2,
    key: 'consult',
    title: '战略咨询'
  },
  {
    id: 3,
    key: 'safe',
    title: 'ISC.AI学苑'
  },
  {
    id: 4,
    key: 'media',
    title: '媒体公关'
  },
  {
    id: 5,
    key: 'create',
    title: '创投孵化'
  },
  {
    id: 6,
    key: 'ecology',
    title: '生态联盟'
  },
  {
    id: 7,
    key: 'wander',
    title: 'ISC数字小镇'
  },
  {
    id: 8,
    key: '',
    title: 'ISC智库'
  },
  {
    id: 9,
    key: '',
    title: '合作伙伴'
  },
  {
    id: 10,
    key: '',
    title: '关于我们'
  }
]
const tagId = ref(0)

onMounted(() => {
  // 显示弹窗
  setTimeout(()=>{
    opacityBtn.value = 1;
  }, 1000)
  // 延时关闭弹窗
  setTimeout(()=>{
    opacityBtn.value = 0;
    setTimeout(() => {
      showDialog.value = false
    }, 1000);
  }, 6000)
})

// const showDialog = ref(true)
//弹窗是否开启
const showDialog = ref(false)
const opacityBtn = ref(0)

// 关闭弹窗
const closeDialog = () => {
  opacityBtn.value = 0;
  setTimeout(() => {
    showDialog.value = false
  }, 1000);
}

// 跳转到2024官网
const jumpNewweb = () => {
  const baseUrl=window.location.origin
  window.open(`${baseUrl}/new/2024.html`, '_blank')
}

function beforeChange(from, to) {
  tagId.value = to
}
function toJump(i) {
  tagId.value = i.id
  carousel.value.goTo(i.id)
}
const rollUpDown = _.throttle(function (e) {
  showOut(e)
}, 100)
function showOut(e) {
  //e.wheelDellta：可以用来获取鼠标的滚动方向，对于得到的值，只看正负，往上滚是正值，往下滚是负值。
  //火狐浏览器不支持这个方法，需要会用e.detail来获取滚轮的滚动方向，向上是负值，向下是正值
  let direction = e.deltaY > 0 ? 'down' : 'up' //deltaY为正则滚轮向下，为负滚轮向上
  //30为用户一次滚动鼠标的wheelDelta的值,30与鼠标滚动一下是几行的不同而不同
  if (direction == 'down' && e.deltaY >= 15) {
    if (tagId.value === tagArr.length - 1) {
      // tagId.value = tagArr.length
      return
    } else {
      // tagId.value += 1
      carousel.value.next()
    }
    return
  }
  if (direction == 'up' && e.deltaY <= -15) {
    if (tagId.value === 0) {
      return
    } else {
      // tagId.value -= 1
      carousel.value.prev()
    }
    return
  }
}
</script>
<template>
  <HeaderMenu @toJumpKey="toJump" />
  <div class="home" @mousewheel="rollUpDown">
    <div class="homePageRight">
      <p class="homePageRightBottom">
        <!-- <a-popover placement="left" :overlayStyle="popoverStyle">
          <template #content>
            <div>
              <p class="homePageRightBottomNavTitle">微信公众号</p>
              <p class="homePageRightBottomNavDesc">扫码关注⼤会实时动态</p>
              <img
                src="https://p2.ssl.qhimg.com/t0130a5bfb97d8c70d9.png"
                alt=""
                class="homePageRightBottomNavQr"
              />
            </div>
          </template>
          <img src="https://s5.ssl.qhres2.com/static/8cc32e2634891d88.svg" alt="微信" />
        </a-popover> -->
        <a-popover placement="left" :overlayStyle="popoverStyle">
          <template #content>
            <div class="phone">
              <p class="phoneTitle">
                <span>商务咨询</span>
                <span>（欢迎咨询）</span>
              </p>
              <p class="phoneDesc">ISC.AI 商务合作</p>
              <p class="phoneNum">马欣 15718876610</p>
              <p class="phoneDesc" style="margin-top: 8px">ISC.AI 学苑合作</p>
              <p class="phoneNum">吕雯雯 13581715706</p>
            </div>
          </template>
          <img src="https://s1.ssl.qhres2.com/static/7411cbb4da516d0f.svg" alt="电话" />
        </a-popover>
        <a-popover placement="left" :overlayStyle="popoverStyle">
          <template #content>
            <div class="phone">
              <p class="phoneTitle">
                <span>邮箱咨询</span>
                <span>（欢迎咨询）</span>
              </p>
              <p class="phoneNum">ISC@360.cn</p>
            </div>
          </template>
          <img src="https://s2.ssl.qhres2.com/static/a44892f4b9955570.svg" alt="邮件" />
        </a-popover>
      </p>
    </div>
    <a-carousel dotsClass="homeDots" dotPosition="left" :beforeChange="beforeChange" ref="carousel">
      <div><HomePage /></div>
      <div v-for="item in coreArr" :key="item.key">
        <HomeCore :item="item" />
      </div>
      <div><Wander /></div>
      <!-- <div><HomeClient /></div> -->
      <div><HomeISC /></div>
      <div><HomePartner /></div>
      <div><HomeAbout /></div>
    </a-carousel>
    <ul class="homeDotsList">
      <li v-for="item in tagArr" :key="item.id">
        <!-- 二级导航 -->
        <!-- <ul class="homePageCoreTop" :class="item.key && item.id === tagId ? 'block' : 'hidden'">
          <li
            v-for="i in coreArr"
            :key="i.key"
            :class="item.key === i.key ? 'pitch' : ''"
            @click="toJump(i)"
          >
            {{ i.title }}
          </li>
        </ul> -->
        <p class="homeDotsListLi" v-if="tagId === item.id">{{ item.title }}</p>
      </li>
    </ul>
    <div v-show="showDialog" class="dialog" :style="{ opacity: opacityBtn }">
      <img class="dialogHeader" @click="closeDialog" src="https://p5.ssl.qhimg.com/t110b9a93016f684a3e2097d5fb.jpg" alt="">
      <img class="dialogImg" @click="jumpNewweb" src="https://p5.ssl.qhimg.com/t110b9a9301e410b4375b3ce119.jpg" alt="" >
    </div>
  </div>
</template>
<style lang="scss" scoped>
.dialog {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .5);
    z-index: 99;
    transition: opacity 2s ease;
  &Header {
    position: absolute;
    top: 20%;
    left: 90%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  &Img{
    width: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 25px;
  }
}
p {
  margin: 0;
}
.block {
  display: block;
}
.hidden {
  display: none !important;
}
.home {
  margin-top: 64px;
  position: relative;
  width: 100%;
  height: 100%;
  background: #364d79;
  &PageCoreTop {
    position: fixed;
    top: -140%;
    transform: translateX(100%);
    left: 100%;
    display: flex;
    justify-content: space-between;
    width: 512px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    list-style: none;
    margin: 0;
    z-index: 2;
    li {
      width: 104px;
      height: 40px !important;
      text-align: center;
      line-height: 40px;
      margin-right: 8px;
      cursor: pointer;
    }

    .pitch {
      background: #ffffff !important;
      border-radius: 4px;
      color: #00ab7a;
      font-weight: bold;
    }

    li:hover {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
    }
  }
  &PageRight {
    position: fixed;
    bottom: 60px;
    right: 24px;
    z-index: 1;
    img {
      cursor: pointer;
    }
    &Bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 56px;
      height: 208px;
      background: #ffffff;
      border-radius: 36px;
      opacity: 0.97;
      margin: 56px 0 0 0;
      border: 1px solid #00ab7a;
      padding: 37px 0;
      img {
        // width: 24px;
        padding: 0 16px;
        // display: block;
      }
      &Nav {
        text-align: center;
        box-shadow: 0 6px 16px 0 #00000033;
        border-radius: 12px;
      }
      &NavTitle {
        font-weight: bold;
        font-size: 18px;
        color: #222222;
        margin-top: 8px;
      }
      &NavDesc {
        font-size: 12px;
        color: #222222;
      }
      &NavQr {
        width: 140px;
        height: 140px;
      }
    }
  }
  &DotsList {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 92px;
    height: 234px;
    left: 44px;
    li {
      list-style: none;
      height: 20px;
      margin-bottom: 26px;
      // border: 1px solid;
    }
    &Li {
      min-width: 92px;
      height: 32px;
      background: url('https://s4.ssl.qhres2.com/static/ff70d60ac8796d3a.svg');
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      line-height: 32px;
      text-align: center;
      list-style: none;
      margin: 0;
    }
  }
}
.phone {
  margin: 12px 8px;
  &Title {
    text-align: left;
    margin-bottom: 10px;
    span:first-child {
      font-weight: bold;
      font-size: 18px;
      color: #222222;
      line-height: 26px;
    }
    span:last-child {
      font-size: 14px;
      color: #222222;
    }
  }
  &Desc {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    // margin: 10px 0 2px;
  }
  &Num {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    color: #00ab7a;
  }
}

:deep(.homeDots) {
  left: 24px !important;
}
:deep(.homeDots li) {
  width: 10px !important;
  height: 10px !important;
  background: #000b4d33 !important;
  border: 1px solid #ffffff !important;
  border-radius: 50% !important;
  margin: 0 !important;
  margin-bottom: 14px !important;
}
:deep(.homeDots button) {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}
:deep(.ant-carousel) {
  height: 100%;
}
:deep(.ant-carousel .slick-dots li) {
  width: 20px;
  height: 4px;
}
:deep(.ant-carousel .slick-dots li.slick-active) {
  width: 20px;
  height: 4px;
}
:deep(.ant-carousel .slick-dots li.slick-active button) {
  background: #00ab7a;
}
:deep(.ant-carousel .slick-dots li button) {
  background: #797a8180;
}
:deep(.ant-popover-content) {
  margin-right: 42px;
}
</style>
